import _ from 'lodash';

import { talentProfileAPI } from '@/api';
import showAPIErrorToast from '@/utils/showAPIErrorToast';

const handleErrorResponse = (err, dispatch, disableRequestIndicator = false) => {
    if (!disableRequestIndicator) {
        showAPIErrorToast(err);
        dispatch('SET_API_RESPONSE_STATUS', 'error');
    }
};

const initialState = {
    id: null,
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    ethnicGroups: [],
    lgbtqiaIdentities: [],
    isResidentOfUs: false,
    location: '',
    workAuthorization: [],
    workPreferences: [],
    openToRelocation: true,
    locationsOfInterest: [],
    workEnvironments: [],
    highestLevelOfEducation: '',
    qualifications: [],
    hasUsGovernmentSecurityClearance: false,
    levelOfClearance: [],
    photoUrl: '',
    aboutMe: '',
    professionalExperiences: [],
    industries: [],
    jobFunctions: [],
    skills: [],
    resumePath: '',
    resumeImages: [],
    linkedinUrl: '',
    fetchingProfile: true,
    apiResponseStatus: '',
    firstTimeModalViewed: true,
    resumeParsed: true,
};

const getters = {
    profile: (state) => {
        return state;
    },
    firstName: ({ firstName }) => {
        return firstName;
    },
    lastName: ({ lastName }) => {
        return lastName;
    },
    email: ({ email }) => {
        return email;
    },
    fetchingProfile: ({ fetchingProfile }) => {
        return fetchingProfile;
    },
    gender: ({ gender }) => {
        return gender;
    },
    lgbtqiaIdentities: ({ lgbtqiaIdentities }) => {
        return lgbtqiaIdentities;
    },
    isResidentOfUs: ({ isResidentOfUs }) => {
        return isResidentOfUs;
    },
    location: ({ location }) => {
        return location;
    },
    workAuthorization: ({ workAuthorization }) => {
        return workAuthorization;
    },
    workPreferences: ({ workPreferences }) => {
        return workPreferences;
    },
    locationsOfInterest: ({ locationsOfInterest }) => {
        return locationsOfInterest;
    },
    workEnvironments: ({ workEnvironments }) => {
        return workEnvironments;
    },
    highestLevelOfEducation: ({ highestLevelOfEducation }) => {
        return highestLevelOfEducation;
    },
    qualifications: ({ qualifications }) => {
        return qualifications;
    },
    hasUsGovernmentSecurityClearance: ({ hasUsGovernmentSecurityClearance }) => {
        return hasUsGovernmentSecurityClearance;
    },
    levelOfClearance: ({ levelOfClearance }) => {
        return levelOfClearance;
    },
    photoUrl: ({ photoUrl }) => {
        return photoUrl;
    },
    aboutMe: ({ aboutMe }) => {
        return aboutMe;
    },
    professionalExperiences: ({ professionalExperiences }) => {
        return professionalExperiences;
    },
    industries: ({ industries }) => {
        return industries;
    },
    jobFunctions: ({ jobFunctions }) => {
        return jobFunctions;
    },
    resumePath: ({ resumePath }) => {
        return resumePath;
    },
    linkedinUrl: ({ linkedinUrl }) => {
        return linkedinUrl;
    },
    ethnicGroups: ({ ethnicGroups }) => {
        return ethnicGroups;
    },
    openToRelocation: ({ openToRelocation }) => {
        return openToRelocation;
    },
    firstTimeModalViewed: ({ firstTimeModalViewed }) => {
        return firstTimeModalViewed;
    },
    resumeParsed: ({ resumeParsed }) => {
        return resumeParsed;
    },
    basicInfo: (state) => {
        const { photoUrl, firstName, lastName, email, aboutMe } = state;

        const basicInfoProps = {
            photoUrl,
            firstName,
            lastName,
            email,
            aboutMe,
        };

        return basicInfoProps;
    },
    professionalExperience: ({ professionalExperiences }) => {
        return professionalExperiences;
    },
    skills: ({ skills }) => {
        return skills;
    },
    apiResponseStatus: ({ apiResponseStatus }) => {
        return apiResponseStatus;
    },
};

const mutations = {
    SET_PROFILE(state, profile) {
        _.assign(state, profile);
    },
    SET_ID(state, id) {
        state.id = id;
    },
    SET_EMAIL(state, email) {
        state.email = email;
    },
    SET_FIRST_NAME(state, firstName) {
        state.firstName = firstName;
    },
    SET_LAST_NAME(state, lastName) {
        state.lastName = lastName;
    },
    SET_GENDER(state, gender) {
        state.gender = gender;
    },
    SET_ETHNIC_GROUPS(state, ethnicGroups) {
        state.ethnicGroups = ethnicGroups;
    },
    SET_LGBTQIA_IDENTITIES(state, lgbtqiaIdentities) {
        state.lgbtqiaIdentities = lgbtqiaIdentities;
    },
    SET_US_RESIDENT(state, isResidentOfUs) {
        state.isResidentOfUs = isResidentOfUs;
    },
    SET_LOCATION(state, location) {
        state.location = location;
    },
    SET_WORK_AUTHORIZATION(state, workAuthorization) {
        state.workAuthorization = workAuthorization;
    },
    SET_WORK_PREFERENCES(state, workPreferences) {
        state.workPreferences = workPreferences;
    },
    SET_LOCATIONS_OF_INTEREST(state, locationsOfInterest) {
        state.locationsOfInterest = locationsOfInterest;
    },
    SET_WORK_ENVIRONMENTS(state, workEnvironments) {
        state.workEnvironments = workEnvironments;
    },
    SET_HIGHEST_EDUCATION(state, highestLevelOfEducation) {
        state.highestLevelOfEducation = highestLevelOfEducation;
    },
    SET_QUALIFICATIONS(state, qualifications) {
        state.qualifications = qualifications;
    },
    SET_US_GOVERNMENT_SECURITY_CLEARANCE(state, hasUsGovernmentSecurityClearance) {
        state.hasUsGovernmentSecurityClearance = hasUsGovernmentSecurityClearance;
    },
    SET_LEVEL_OF_CLEARANCE(state, levelOfClearance) {
        state.levelOfClearance = levelOfClearance;
    },
    SET_PHOTO_URL(state, photoUrl) {
        state.photoUrl = photoUrl;
    },
    SET_ABOUT_ME(state, aboutMe) {
        state.aboutMe = aboutMe;
    },
    SET_PROFESSIONAL_EXPERIENCES(state, professionalExperiences) {
        state.professionalExperiences = professionalExperiences;
    },
    SET_INDUSTRIES(state, industries) {
        state.industries = industries;
    },
    SET_JOB_FUNCTIONS(state, jobFunctions) {
        state.jobFunctions = jobFunctions;
    },
    SET_RESUME_PATH(state, resumePath) {
        state.resumePath = resumePath;
    },
    SET_LINKEDIN_URL(state, linkedinUrl) {
        state.linkedinUrl = linkedinUrl;
    },
    SET_FETCHING_PROFILE(state, fetchingProfile) {
        state.fetchingProfile = fetchingProfile;
    },
    SET_OPEN_TO_RELOCATION(state, openToRelocation) {
        state.openToRelocation = openToRelocation;
    },
    SET_API_RESPONSE_STATUS(state, apiResponseStatus) {
        state.apiResponseStatus = apiResponseStatus;
    },
    SET_FIRST_TIME_MODAL_VIEWED(state, firstTimeModalViewed) {
        state.firstTimeModalViewed = firstTimeModalViewed;
    },
    SET_RESUME_PARSED(state, resumeParsed) {
        state.resumeParsed = resumeParsed;
    },
};

const actions = {
    async FETCH_PROFILE({ dispatch, state, rootState }) {
        dispatch('SET_FETCHING_PROFILE', true);
        let waitUntilResumeParsed = localStorage.getItem('waitUntilResumeParsed');
        if (waitUntilResumeParsed) {
            waitUntilResumeParsed = JSON.parse(waitUntilResumeParsed);
            dispatch('SET_RESUME_PARSED', false);
        }
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };
        await talentProfileAPI
            .get(
                `${talentProfileAPI.defaults.endpoints.getProfile}${rootState.app.talentUserId}?wait_until_resume_parsed=${waitUntilResumeParsed}`,
                options,
            )
            .then(({ data }) => {
                localStorage.removeItem('waitUntilResumeParsed');
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });

                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));
                if (waitUntilResumeParsed) {
                    updatedProfile.resumeParsed = false;
                } else {
                    updatedProfile.resumeParsed = true;
                }
                dispatch('SET_PROFILE', updatedProfile);
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            })
            .finally(() => {
                dispatch('SET_FETCHING_PROFILE', false);
            });
    },
    async PATCH_UPDATE_PROFILE({ dispatch, state, rootState }, patchPayload) {
        const { disableRequestIndicator, ...patch } = patchPayload;

        if (!disableRequestIndicator) {
            dispatch('SET_API_RESPONSE_STATUS', 'pending');
        }
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };

        const transformedPatch = {};

        Object.entries(patch).forEach(([key, value]) => {
            transformedPatch[_.snakeCase(key)] = value;
        });

        await talentProfileAPI
            .patch(
                `${talentProfileAPI.defaults.endpoints.patchProfile}${rootState.app.talentUserId}`,
                transformedPatch,
                options,
            )
            .then(({ data }) => {
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });
                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));
                dispatch('SET_PROFILE', updatedProfile);
                if (!disableRequestIndicator) {
                    dispatch('SET_API_RESPONSE_STATUS', 'success');
                }
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch, disableRequestIndicator);
            });
    },
    async ADD_NEW_EXPERIENCE({ dispatch, state, rootState }, experience) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };
        const transformedExperience = {};

        Object.entries(experience).forEach(([key, value]) => {
            transformedExperience[_.snakeCase(key)] = value;
        });
        await talentProfileAPI
            .post(
                `${talentProfileAPI.defaults.endpoints.addProfessionalExperience}${rootState.app.talentUserId}`,
                transformedExperience,
                options,
            )
            .then(({ data }) => {
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });

                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));

                dispatch('SET_PROFILE', updatedProfile);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async UPDATE_PROFESSIONAL_EXPERIENCE({ dispatch, state, rootState }, experience) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };
        const transformedExperience = {};

        Object.entries(experience).forEach(([key, value]) => {
            if (key !== '_id') {
                transformedExperience[_.snakeCase(key)] = value;
            } else {
                transformedExperience[key] = value;
            }
        });
        await talentProfileAPI
            .put(
                `${talentProfileAPI.defaults.endpoints.updateProfessionalExperience}${rootState.app.talentUserId}`,
                transformedExperience,
                options,
            )
            .then(({ data }) => {
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });
                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));
                dispatch('SET_PROFILE', updatedProfile);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async DELETE_PROFESSIONAL_EXPERIENCE({ dispatch, state, rootState }, id) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };
        await talentProfileAPI
            .delete(
                `${talentProfileAPI.defaults.endpoints.getProfile}${rootState.app.talentUserId}/professionalExperience/${id}`,
                options,
            )
            .then(({ data }) => {
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });

                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));

                dispatch('SET_PROFILE', updatedProfile);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async ADD_QUALIFICATION({ dispatch, state, rootState }, qualification) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };
        const transformedQualification = {};

        Object.entries(qualification).forEach(([key, value]) => {
            transformedQualification[_.snakeCase(key)] = value;
        });
        await talentProfileAPI
            .post(
                `${talentProfileAPI.defaults.endpoints.addQualification}${rootState.app.talentUserId}`,
                transformedQualification,
                options,
            )
            .then(({ data }) => {
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });

                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));

                dispatch('SET_PROFILE', updatedProfile);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async UPDATE_QUALIFICATION({ dispatch, state, rootState }, qualification) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };
        const transformedQualification = {};

        Object.entries(qualification).forEach(([key, value]) => {
            if (key !== '_id') {
                transformedQualification[_.snakeCase(key)] = value;
            } else {
                transformedQualification[key] = value;
            }
        });
        await talentProfileAPI
            .put(
                `${talentProfileAPI.defaults.endpoints.updateQualification}${rootState.app.talentUserId}`,
                transformedQualification,
                options,
            )
            .then(({ data }) => {
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });

                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));

                dispatch('SET_PROFILE', updatedProfile);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    async DELETE_QUALIFICATION({ dispatch, state, rootState }, id) {
        dispatch('SET_API_RESPONSE_STATUS', 'pending');
        const options = {
            headers: { Authorization: `Bearer ${rootState.app.talentToken}` },
        };
        await talentProfileAPI
            .delete(
                `${talentProfileAPI.defaults.endpoints.getProfile}${rootState.app.talentUserId}/qualification/${id}`,
                options,
            )
            .then(({ data }) => {
                const camelCaseData = _.mapKeys(data, (value, key) => {
                    return _.camelCase(key);
                });

                const updatedProfile = _.pick(_.cloneDeep(camelCaseData), _.keys(state));

                dispatch('SET_PROFILE', updatedProfile);
                dispatch('SET_API_RESPONSE_STATUS', 'success');
            })
            .catch((err) => {
                handleErrorResponse(err, dispatch);
            });
    },
    SET_PROFILE({ commit }, profile) {
        commit('SET_PROFILE', profile);
    },
    SET_ID({ commit }, id) {
        commit('SET_ID', id);
    },
    SET_EMAIL({ commit }, email) {
        commit('SET_EMAIL', email);
    },
    SET_FIRST_NAME({ commit }, firstName) {
        commit('SET_FIRST_NAME', firstName);
    },
    SET_LAST_NAME({ commit }, lastName) {
        commit('SET_LAST_NAME', lastName);
    },
    SET_GENDER({ commit }, gender) {
        commit('SET_GENDER', gender);
    },
    SET_ETHNIC_GROUPS({ commit }, ethnicGroups) {
        commit('SET_ETHNIC_GROUPS', ethnicGroups);
    },
    SET_LGBTQIA_IDENTITIES({ commit }, lgbtqiaIdentities) {
        commit('SET_LGBTQIA_IDENTITIES', lgbtqiaIdentities);
    },
    SET_US_RESIDENT({ commit }, isResidentOfUs) {
        commit('SET_US_RESIDENT', isResidentOfUs);
    },
    SET_LOCATION({ commit }, location) {
        commit('SET_LOCATION', location);
    },
    SET_WORK_AUTHORIZATION({ commit }, workAuthorization) {
        commit('SET_WORK_AUTHORIZATION', workAuthorization);
    },
    SET_WORK_PREFERENCES({ commit }, workPreferences) {
        commit('SET_WORK_PREFERENCES', workPreferences);
    },
    SET_LOCATIONS_OF_INTEREST({ commit }, locationsOfInterest) {
        commit('SET_LOCATIONS_OF_INTEREST', locationsOfInterest);
    },
    SET_WORK_ENVIRONMENTS({ commit }, workEnvironments) {
        commit('SET_WORK_ENVIRONMENTS', workEnvironments);
    },
    SET_HIGHEST_EDUCATION({ commit }, highestLevelOfEducation) {
        commit('SET_HIGHEST_EDUCATION', highestLevelOfEducation);
    },
    SET_QUALIFICATIONS({ commit }, qualifications) {
        commit('SET_QUALIFICATIONS', qualifications);
    },
    SET_US_GOVERNMENT_SECURITY_CLEARANCE({ commit }, hasUsGovernmentSecurityClearance) {
        commit('SET_US_GOVERNMENT_SECURITY_CLEARANCE', hasUsGovernmentSecurityClearance);
    },
    SET_LEVEL_OF_CLEARANCE({ commit }, levelOfClearance) {
        commit('SET_LEVEL_OF_CLEARANCE', levelOfClearance);
    },
    SET_PHOTO_URL({ commit }, photoUrl) {
        commit('SET_PHOTO_URL', photoUrl);
    },
    SET_ABOUT_ME({ commit }, aboutMe) {
        commit('SET_ABOUT_ME', aboutMe);
    },
    SET_PROFESSIONAL_EXPERIENCES({ commit }, professionalExperiences) {
        commit('SET_PROFESSIONAL_EXPERIENCES', professionalExperiences);
    },
    SET_INDUSTRIES({ commit }, industries) {
        commit('SET_INDUSTRIES', industries);
    },
    SET_JOB_FUNCTIONS({ commit }, jobFunctions) {
        commit('SET_JOB_FUNCTIONS', jobFunctions);
    },
    SET_RESUME_PATH({ commit }, resumePath) {
        commit('SET_RESUME_PATH', resumePath);
    },
    SET_LINKEDIN_URL({ commit }, linkedinUrl) {
        commit('SET_LINKEDIN_URL', linkedinUrl);
    },
    SET_TALENT_USER_ID({ commit }, talentUserId) {
        commit('SET_TALENT_USER_ID', talentUserId);
    },
    SET_FETCHING_PROFILE({ commit }, fetchingProfile) {
        commit('SET_FETCHING_PROFILE', fetchingProfile);
    },
    SET_OPEN_TO_RELOCATION({ commit }, openToRelocation) {
        commit('SET_OPEN_TO_RELOCATION', openToRelocation);
    },
    SET_API_RESPONSE_STATUS({ commit }, apiResponseStatus) {
        commit('SET_API_RESPONSE_STATUS', apiResponseStatus);
    },
    SET_FIRST_TIME_MODAL_VIEWED({ commit }, firstTimeModalViewed) {
        commit('SET_FIRST_TIME_MODAL_VIEWED', firstTimeModalViewed);
    },
    SET_RESUME_PARSED({ commit }, resumeParsed) {
        commit('SET_RESUME_PARSED', resumeParsed);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};
